import axios from "axios";
import { getLocalStorage } from "../../helper/localStorage";
import axiosClient from "../axiosClient";
import { calcPriceForm } from "../../types/common";

const languageLocal = getLocalStorage("language");

export const serviceApi = {
  async getCategory() {
    const url = "/request-category";
    return axiosClient.get(url);
  },
  async getServices(id: string) {
    const url = `/request-category-services?category_id=${id}`;
    return axiosClient.get(url);
  },
  async getServiceDetail(id: string) {
    const url = `/service/detail-service?id=${id}`;
    return axiosClient.get(url);
  },
  async calculatePrice(data: calcPriceForm) {
    const url = `/service/calc-price`;
    return axiosClient.post(url, data);
  },
  async getCategoryForTasker() {
    const url = "/tasker/tasker_home";
    return axiosClient.get(url);
  },
  async getScheduleStatistics() {
    const url = "/tasker/schedule-statistics";
    return axiosClient.get(url);
  },
  async getAllBank() {
    const url = "https://bluecare-dev-api.moncow.com/banklist.json";
    const axiosClient = axios.create({
      baseURL: url,
      headers: {
        "content-type": "application/json",
        lang: languageLocal || "vi",
      },
    });
    return axiosClient.get(url);
  },
  async getAllCity() {
    const url = "https://bluecare-dev-api.moncow.com/json_data_vn_units.json";
    const axiosClient = axios.create({
      baseURL: url,
      headers: {
        "content-type": "application/json",
        lang: languageLocal || "vi",
      },
    });
    return axiosClient.get(url);
  },
  async getAddressSuggestions() {
    const url = "/service/address-suggestions";
    return axiosClient.get(url);
  },
  async requestService(data: any) {
    const url = "/service/request-service";
    return axiosClient.post(url, data);
  },
  async paymentEbanking(data: any) {
    const url = "/payment/ebanking";
    return axiosClient.get(url, { params: { _token: data.token, job_id: data.job_id } });
  },
};
